<template>
  <div id="past-appointment">
    <div class="title">Tüm Görüşmeler</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Tüm Görüşmeler</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <el-tooltip class="item" effect="dark" content="Export" placement="top">
          <el-button class="import" @click="exportFile()"><i class="icon-export"></i></el-button>
        </el-tooltip>
      </div>
    </div>

    <div class="table-general">
      <div class="search-status">
        <div class="status">
          <div class="show">Göster</div>
          <el-select v-model="filter.PageSize" placeholder="Seçiniz...">
            <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="filter.Status" placeholder="Durum" clearable @clear="filter.Status = null" v-if="getEnums != null">
            <el-option v-for="item in getEnums.find((x) => x.Title == 'AppointmentStatus').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
          </el-select>
          <el-date-picker v-model="filter.DateRange" align="center" format="dd.MM.yyyy" :default-time="['3:00:00', '23:59:59']" type="daterange" range-separator="-" start-placeholder="..." end-placeholder="..." prefix-icon="icon-calendar"> </el-date-picker>
          <el-select v-model="filter.Rate" placeholder="Değerlendirme" clearable @clear="filter.Rate = null">
            <el-option :value="1">
              <template>
                <el-rate :value="1" disabled show-score text-color="#ff9900" score-template="{value} points"> </el-rate>
              </template>
            </el-option>
            <el-option :value="2">
              <template>
                <el-rate :value="2" disabled show-score text-color="#ff9900" score-template="{value} points"> </el-rate>
              </template>
            </el-option>
            <el-option :value="3">
              <template>
                <el-rate :value="3" disabled show-score text-color="#ff9900" score-template="{value} points"> </el-rate>
              </template>
            </el-option>
            <el-option :value="4">
              <template>
                <el-rate :value="4" disabled show-score text-color="#ff9900" score-template="{value} points"> </el-rate>
              </template>
            </el-option>
            <el-option :value="5">
              <template>
                <el-rate :value="5" disabled show-score text-color="#ff9900" score-template="{value} points"> </el-rate>
              </template>
            </el-option>
          </el-select>
        </div>

        <div class="search">
          <el-input placeholder="Kayıt Ara" v-model="filter.SearchTerm" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
          </el-input>
        </div>
      </div>

      <div class="table">
        <el-table :data="result.Appointments" style="width: 100%" v-on:sort-change="sortHandler">
          <el-table-column label="Id" width="110">
            <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
          </el-table-column>

          <el-table-column label="DOKTOR" width="200">
            <template slot-scope="scope" v-if="scope.row.DoctorUser != null">
              {{ scope.row.DoctorUser.Title }}
            </template>
          </el-table-column>
          <!--++NC Tüm görüşmelerime mail ilave edildi-->
          <el-table-column label="HASTA" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.User.Kid != null"> {{ scope.row.User.Kid.FirstName[0] + "***" + " " + scope.row.User.Kid.LastName[0] + "***" + " " + "(Çocuk)" }} </span>
              <span v-else>
                {{
                  scope.row.User.Title.trim()
                    .split(" ")
                    .map((x) => x[0] + "***")
                    .reduce((x, y) => x + " " + y, "")
                }}
              </span>
              <div v-if="scope.row.User.PhoneNumber != null">{{ scope.row.User.PhoneNumber }}</div>
              <div v-if="scope.row.User.Email != null">{{ scope.row.User.Email }}</div>
            </template>
          </el-table-column>
          <el-table-column label="KURUM" width="200">
            <template slot-scope="scope" v-if="scope.row.User != null">
              {{ scope.row.User.Kurum }}
            </template>
          </el-table-column>

          <el-table-column prop="price" label="ÜCRET" width="130">
            <template slot-scope="scope"> {{ scope.row.PaymentInfo.PaidPrice }}TL </template>
          </el-table-column>

          <el-table-column prop="status" label="DURUM" width="170">
            <template slot-scope="scope">
              <div class="color-dot">
                <div class="dot" :class="{ yellow: scope.row.Status == 1, red: scope.row.Status == 4, orange: scope.row.Status == 2, green: scope.row.Status == 3 }"></div>
                {{ getEnumsDisplay("AppointmentStatus", scope.row.Status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="GÖRÜŞME SÜRESİ" width="170" sortable="custom" prop="TotalAppointmentDurationInSeconds">
            <template slot-scope="scope">
              <div v-if="scope.row.TotalAppointmentDurationInSeconds / 60 > 1">{{ (scope.row.TotalAppointmentDurationInSeconds / 60).toFixed(0) }} dk</div>
              <div v-else>{{ scope.row.TotalAppointmentDurationInSeconds + " sn" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="RANDEVU ZAMANI" width="200" sortable="custom" prop="InterviewInfo.AppointmentDate">
            <template slot-scope="scope">
              <div class="color-dot">
                <div v-if="scope.row.Status == 5">{{ $moment(scope.row.InterviewInfo.AppointmentDate).format("Do MMMM YYYY, h:mm") }}</div>
                <div v-else>...</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="StarRating" label="DEĞERLENDİRME" width="200">
            <template slot-scope="scope">
              <el-rate v-model="scope.row.RateInfo.Rate" disabled text-color="#ff9900" score-template="{value} points" v-if="scope.row.RateInfo != null && scope.row.RateInfo.Rate != null"> </el-rate>
            </template>
          </el-table-column>

          <el-table-column label="TARİH" width="140" sortable="custom" prop="CreatedDateTime">
            <template slot-scope="scope">
              {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY HH:mm") }}
            </template>
          </el-table-column>
          <el-table-column label="PLATFORM" width="190">
            <template slot-scope="scope">
              {{ getEnumsDisplay("DevicePlatform", scope.row.AppointmentRequestFrom) }}
            </template>
          </el-table-column>

          <el-table-column label="" width="130" align="center">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="goDetails(scope.row)" style="display: block; width: 100%">Detayları Görüntüle</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item v-if="scope.row.Status == 3 && scope.row.Permission">
                    <span @click="downloadAppointment(scope.row.ID)" style="display: block; width: 100%">Görüşmeyi İndir</span>
                  </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kayıt bulunamadı.</div>
              <div class="no-data-text">Herhangi bir geçmiş görüşme kaydı bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>
      <div class="pagination">
        <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > result.TotalCount ? result.TotalCount : filter.PageSize * filter.Page }} {{ $localization("web-pagination-info-text") }} {{ result.TotalCount }}</div>
        <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="result.TotalCount"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      
      result: {
        Appointments: [],
        TotalCount: 0,
        PageCount: 0,
      },
      pagesize: [10, 20, 30, 40],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async sortHandler(params) {
      // params objesi: {columns, prop, order}
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    exportFile() {
      var label = "GecmisGorusmeler.xlsx";
      this.$client
        .get("/Appointment/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    goDetails(item) {
      this.$router.push("/admin/gecmis-gorusmeler-detay/" + item.ID);
    },
    async getData() {
      var res = await this.$client.post("/Appointment/List", this.filter);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.result = res.data.Data;
      }
    },
  },
  computed: {
    getEnums() {
      return this.$store.state.auth.enums;
    },
    filter() {
      return this.$store.getters.getPastAppointmentFilter;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: async function () {
        await this.getData();
      },
    },
    "filter.DateRange": function () {
      if (this.filter.DateRange != null) {
        this.filter.CreatedAtMin = this.filter.DateRange[0];
        this.filter.CreatedAtMax = this.filter.DateRange[1];
      } else {
        this.filter.CreatedAtMin = null;
        this.filter.CreatedAtMax = null;
      }
      this.getData();
    },
    "filter.SearchTerm": function () {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getData();
      }, 400);
    },
    "filter.PageSize": function () {
      this.getData();
    },
    "filter.Page": function () {
      this.getData();
    },
    "filter.Status": function () {
      this.getData();
    },
    "filter.Rate": function () {
      this.getData();
    },
  },
};
</script>

<style lang="less">
#past-appointment {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .table-general {
    display: flex;
    flex-direction: column;

    .search-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      .search {
        .el-input {
          width: 290px;
          border-radius: 5px;

          input {
            border: none;
            border-radius: 5px;
            background-color: white;
            color: #8697a8;
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .show {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #44566c;
          margin-right: 10px;
        }

        .el-select {
          border-radius: 8px;
          border: none;

          .el-input {
            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        font-size: 15px;
        font-weight: 400;
        color: #8697a8;
      }

      .el-pagination {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 8px;

        button {
          background-color: white;
          padding: 0;
          margin: 0;
          height: 100% !important;

          &.btn-prev {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid #eaedf0;
          }

          &.btn-next {
            border-radius: 0 8px 8px 0;

            border-left: 1px solid #eaedf0;
          }
        }

        .el-pager {
          margin: 0 10px;
          padding: 5px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          justify-content: space-between;

          li {
            padding: 0;
            margin: 0;
            background-color: white;

            &.active {
              background-color: #234a98;
              border-radius: 8px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      .el-select__caret {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.orange {
        background-color: #ff8a48;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }
}
</style>
